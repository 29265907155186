<template>
    <div
        v-if="buffer.error != ''"
        class="alert alert-danger"
    >
        {{ buffer.error }}
    </div>

    <div v-if="!buffer.error">
        <year-selector
            v-model="buffer.selectedYear"
            class="responsive-mx-4"
            @year-updated="updateYear()"
        />

        <div>
            <p class="h1 text-center my-4">
                {{ buffer.company.companyName }}
            </p>
        </div>

        <div
            class="flex-wrap d-flex justify-content-center"
        >
            <div
                v-if="displayableData.firstLine.length == 0"
                style="width: 100%"
                class="card responsive-mx-4"
            >
                <loading-layer :is-loading="buffer.isLoading" />
                <p class="h4 text-center my-4 text-secondary">
                    {{ __('COMMON.NO_KPIS') }}
                </p>
            </div>
            <numeric-metric
                v-for="spec in displayableData.firstLine"
                :key="spec.title"
                :value="spec.value"
                :title="spec.title"
                :unit="spec.unit"
            />
        </div>

        <div
            v-if="displayableData.firstLine.length > 0"
            class="responsive-mx-4 mx-4"
        >
            <span class="h6">
                {{ __('COMMON.CATEGORY_COMPARE') }} <toggle-switch v-model="buffer.selectCategorical" />
            </span>

            <div
                v-for="fac in Object.keys(buffer.chartSelfData)"
                :key="fac"
                class="card d-block my-4 p-4"
            >
                <collapsable-div>
                    <template #title>
                        <span class="h4 mr-4">
                            {{ fac }}
                        </span>

                        <dropdown-list
                            v-if="buffer.dropdownKpis[fac] != undefined"
                            v-model="buffer.selectedKpi[fac]"
                            :range="buffer.dropdownKpis[fac]"
                        />
                    </template>
                    <template #content>
                        <mean-real-barchart
                            v-if="displayableData.chartDatasets[fac] != undefined"
                            :datasets="displayableData.chartDatasets[fac][buffer.selectedKpi[fac]]"
                            class="p-4"
                        />
                    </template>
                </collapsable-div>
            </div>
        </div>
    </div>
</template>

<script>
import API from '../../utils/api-utils.js';
import __ from '../../utils/translator.js';

import {
    getKpi,
    getKpiOperations,
    getActivityName, 
    getSpecieName,
    getCategoryName } from '../../db/db-utils.js';

import YearSelector from '../../components/year_selector.vue';
import NumericMetric from '../../components/numeric_metric.vue';
import LoadingLayer from '../../components/loading_layer.vue';
import DropdownList from '../../components/dropdown_list.vue';
import MeanRealBarchart from '../../components/mean-real_barchart.vue';
import CollapsableDiv from '../../components/collapsable_div.vue';
import ToggleSwitch from '../../components/toggle-switch.vue';

import Constants from '../../Constants.js';
import Operation from '../../utils/operation.js';

export default {

    components: {
        YearSelector,
        NumericMetric,
        LoadingLayer,
        DropdownList,
        MeanRealBarchart,
        CollapsableDiv,
        ToggleSwitch
    },

    inject: ['COMPANY', 'categoryHistory'],

    data() {
        return {
            buffer: {
                company: this.COMPANY.value,

                selectedYear: Constants.MIN_KPIDATE_VALUE,

                /**
                 * index
                 * var[facility] -> kpiId
                 */
                selectedKpi: {},

                kpisInFirstLine: [8, 9, 10, 11, 12, 13],

                /**
                 * index
                 * var[facility] -> [{name: name, value: value}]
                 */
                dropdownKpis: {},


                /**
                 * index
                 * var[kpiId] -> [{name: name, value: value}]
                 */
                meanChartDatasets: {},

                /*
                 * index
                 * var[facility][kpiId][specie] -> value
                 */
                chartSelfData: {},

                selectCategorical: false,
                categoryHistory: {},


                isLoading: true,
                error: ''
            },

            displayableData: {
                firstLine: [],

                /**
                 * index
                 * var[facility][kpiId]
                 */
                chartDatasets: {}
            }
        }
    },

    watch: {
        'buffer.selectCategorical'(newVal) {
            this.buffer.selectCategorical = newVal;

            this.buffer.meanChartDatasets = {};
            this.displayableData.chartDatasets = {};

            this.fetchMeans()
            .then(() => this.buildChartDataset() )
            .finally(() => this.buffer.isLoading = false );
        }
    },

    created() {
        this.updateYear();
    },

    methods: {
        resetData() {
            this.buffer.selectedKpi = {};
            this.displayableData.firstLine = [];

            this.displayableData.chartDatasets = {};
            this.buffer.dropdownKpis = {};
            this.buffer.chartSelfData = {};
            this.buffer.meanChartDatasets = {};
        },

        updateYear() {
            this.buffer.isLoading = true;
            this.resetData();

            const promises = [];

            //Fetch specific data
            promises.push(API.analytics.filter({
                    year: this.buffer.selectedYear,
                    kpiId: this.buffer.kpisInFirstLine
                })
                .then(response => {
                    if (response.ok) {
                        this.prepareRawData(response.data);
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(() => this.buffer.error = 'Network error, try again later.')
            );


            //Fetch means
            promises.push(this.fetchMeans());

            //Ensures category history is initialized before displaying data
            promises.push(new Promise(resolve => {
                this.categoryHistory.then(history => {
                    this.buffer.categoryHistory = history;
                    resolve();
                });
            }));

            //Done
            Promise.all(promises)
                //Once all is finished, set a default selected facility
                .then(() => this.buildDropdowns() )
                .then(() => this.buildChartDataset() )
                .finally(() => this.buffer.isLoading = false );
        },


        buildDropdowns() {
            Object.entries(this.buffer.chartSelfData).forEach(([fac, kpiObj]) => {
                Object.entries(kpiObj).forEach(({ 0: kpiId }) => {
                    // console.log(specieObj);
                    const kpi = getKpi(kpiId);

                    if (this.buffer.dropdownKpis[fac] == undefined) {
                        this.buffer.dropdownKpis[fac] = [{ name: kpi.name, value: kpiId }];
                    } else {
                        this.buffer.dropdownKpis[fac].push({ name: kpi.name, value: kpiId });
                    }
                });

                //Default selected kpi
                this.buffer.selectedKpi[fac] = this.buffer.dropdownKpis[fac][0].value;
            });

        },


        buildChartDataset() {
            //Reference
            const chartDatasets = this.displayableData.chartDatasets;
            const chartMeansData = this.buffer.meanChartDatasets;

            Object.entries(this.buffer.chartSelfData).forEach(([fac, kpiObj]) => {
                Object.entries(kpiObj).forEach(([kpiId, specieObj]) => {

                    const obj1 = { name: __('COMMON.MY_FACILITY'), data: [] };

                    Object.entries(specieObj).forEach(([specieId, value]) => {
                        obj1.data.push({ name: getSpecieName(specieId), value });
                    });

                    if (chartDatasets[fac] == undefined) {
                        chartDatasets[fac] = {};
                        chartDatasets[fac][kpiId] = [obj1];
                    } else {
                        chartDatasets[fac][kpiId] = [obj1];
                    }


                    const obj2 = { name: '', data: [] };
                    if (this.buffer.selectCategorical) {
                        //Category
                        const categoryId = this.buffer.categoryHistory[this.buffer.selectedYear][fac];
                        obj2.name = `${getCategoryName(categoryId)} average`;
                        Object.values(chartMeansData[categoryId][kpiId])
                            .forEach(o => { obj2.data.push(o) });

                    } else {
                        //Global
                        obj2.name = __('COMMON.SECTOR_AVERAGE');
                        chartMeansData[kpiId].forEach(o => { obj2.data.push(o) });

                    }

                    chartDatasets[fac][kpiId].push(obj2);
                });
            });
        },


        prepareRawData(rawData) {
            const firstLine = {};

            //Reference
            const chartSelfData = this.buffer.chartSelfData;


            rawData.forEach(kpi => {
                // First line kpis
                //firstLine[facility][activity][kpiId] -> [values]
                if (firstLine[kpi.facility] == undefined) {
                    firstLine[kpi.facility] = {};
                    firstLine[kpi.facility][kpi.activityId] = {};
                    firstLine[kpi.facility][kpi.activityId][kpi.kpiId] = [kpi.value];
                } else if (firstLine[kpi.facility][kpi.activityId] == undefined) {
                    firstLine[kpi.facility][kpi.activityId] = {};
                    firstLine[kpi.facility][kpi.activityId][kpi.kpiId] = [kpi.value];
                } else if (firstLine[kpi.facility][kpi.activityId][kpi.kpiId] == undefined) {
                    firstLine[kpi.facility][kpi.activityId][kpi.kpiId] = [kpi.value];
                } else {
                    firstLine[kpi.facility][kpi.activityId][kpi.kpiId].push(kpi.value);
                }

                //My data of the charts
                if (chartSelfData[kpi.facility] == undefined) {
                    chartSelfData[kpi.facility] = {};
                    chartSelfData[kpi.facility][kpi.kpiId] = {};
                    chartSelfData[kpi.facility][kpi.kpiId][kpi.specieId] = kpi.value;
                } else if (chartSelfData[kpi.facility][kpi.kpiId] == undefined) {
                    chartSelfData[kpi.facility][kpi.kpiId] = {};
                    chartSelfData[kpi.facility][kpi.kpiId][kpi.specieId] = kpi.value;
                } else {
                    chartSelfData[kpi.facility][kpi.kpiId][kpi.specieId] = kpi.value;
                }
            });


            //Display first line data
            const operationsObj = {};
            const activityCalculation = {};
            Object.entries(firstLine).forEach(([facility, activityObj]) => {
                activityObj; facility;

                const specieCalculation = {};

                Object.entries(activityObj).forEach(([activityId, kpiObj]) => {
                    activityId; kpiObj;

                    //Specie calculation
                    Object.entries(kpiObj).forEach(([kpiId, values]) => {
                        if (operationsObj[kpiId] == undefined) {
                            operationsObj[kpiId] = {};
                            operationsObj[kpiId] = getKpiOperations(kpiId);
                        }

                        const oper = new Operation(operationsObj[kpiId].specieOperation);
                        const result = oper.compute(values);

                        if (specieCalculation[kpiId] == undefined) {
                            specieCalculation[kpiId] = [result];
                        } else {
                            specieCalculation[kpiId].push(result);
                        }
                    });
                });

                //Activity calculation
                Object.entries(specieCalculation).forEach(([kpiId, values]) => {
                        const oper = new Operation(operationsObj[kpiId].activityOperation);
                        const result = oper.compute(values);

                        if (activityCalculation[kpiId] == undefined) {
                            activityCalculation[kpiId] = [result];
                        } else {
                            activityCalculation[kpiId].push(result);
                        }
                });
            });

            Object.entries(activityCalculation).forEach(([kpiId, values]) => {
                const oper = new Operation(operationsObj[kpiId].facilityOperation, { decimals: 2 });
                const kpiSpec = getKpi(kpiId);

                this.displayableData.firstLine.push({
                    value: oper.compute(values),
                    title: kpiSpec.name,
                    unit: kpiSpec.unit
                });
            });
        },

        prepareMeansData(rawData) {
            //Reference
            const chartMeansData = this.buffer.meanChartDatasets;

            if (this.buffer.selectCategorical) {
                //Category
                rawData.forEach(kpi => {
                    const specie = getSpecieName(kpi.specieId);

                    if (chartMeansData[kpi.categoryId] == undefined) {
                        chartMeansData[kpi.categoryId] = {};
                        chartMeansData[kpi.categoryId][kpi.kpiId] = [
                            { name: specie, value: kpi.mean }
                        ];

                    } else if (chartMeansData[kpi.categoryId][kpi.kpiId] == undefined) {
                        chartMeansData[kpi.categoryId][kpi.kpiId] = [
                            { name: specie, value: kpi.mean }
                        ];

                    } else {
                        chartMeansData[kpi.categoryId][kpi.kpiId].push(
                            { name: specie, value: kpi.mean }
                        );
                    }
                });
            } else {
                //Global
                rawData.forEach(kpi => {
                    const specie = getSpecieName(kpi.specieId);

                    if (chartMeansData[kpi.kpiId] == undefined) {
                        chartMeansData[kpi.kpiId] = [
                            { name: specie, value: kpi.mean }
                        ];
                    } else {
                        chartMeansData[kpi.kpiId].push(
                            { name: specie, value: kpi.mean }
                        );
                    }
                });
            }
        },

        async fetchMeans() {
            return API.analytics.mean({
                    year: this.buffer.selectedYear,
                    kpiId: this.buffer.kpisInFirstLine,
                    activityId: [null],
                    categorical: this.buffer.selectCategorical
                })
                .then(response => {
                    if (response.ok) {
                        this.prepareMeansData(response.data);
                    } else {
                        this.buffer.error = response.error.message;
                    }
                })
                .catch(() => this.buffer.error = 'Network error, try again later.');
        },

        getActivityName,
        __ //translator
    }
}
</script>